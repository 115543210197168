import { Box, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import { Crosswarp } from '../../types/api-types';
import { COMMON_LOCALES, PREFILTERS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import resizeImage from '../../utils/image';
import useRoles from '../../utils/roles-hook';
import { getCleanSearch } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import ImageWithError from '../ImageWithError';
import Restricted from '../permission/Restricted';
import usePermission from '../permission/usePermission';
// ---- Props needed to have correct overflow on text in card ----
const TEXT_OVERFLOW_PROPS = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    w: '100%',
};

interface CrosswarpGridCardProps {
    imageRatio?: string,
    statusKey?: string,
    crosswarp: Crosswarp,
    isReserved?: boolean
}

export default function CrosswarpGridCard(props: CrosswarpGridCardProps) {
    const { imageRatio, statusKey, crosswarp, isReserved } = props;

    const { search } = useLocation();
    const navigate = useCustomNavigate();
    const { isAdmin } = useRoles();
    const { t } = useTranslation(COMMON_LOCALES);
    const crosswarpWarpPermission = usePermission('crosswarp.warp', crosswarp.crosswarp_status);
    const crosswarpWritePermission = usePermission('crosswarp.write', crosswarp.crosswarp_status);

    const [isReservedLocal, setIsReservedLocal] = useState<boolean>(!!isReserved);
    const [cardWidth, setCardWidth] = useState<number>(0);

    // ---- This is used to change the font size according to the card width ----
    const ref = useRef<HTMLDivElement>(null);

    const fontSize = useMemo(() => {
        if (cardWidth < 200) { return 9; }

        return 'md';
    }, [cardWidth]);

    const warpId = useMemo(() => {
        if (!crosswarp) {
            return null;
        }
        let localWarpId = crosswarp[`warp_${crosswarp.garment_gender?.toLowerCase()}_id`];

        if (!localWarpId) {
            // Choose correct Warp Id
            if (crosswarp.warp_status === crosswarp.warp_male_status) {
                localWarpId = crosswarp.warp_male_id;
            } else if (crosswarp.warp_status === crosswarp.warp_female_status) {
                localWarpId = crosswarp.warp_female_id;
            } else if (crosswarp.warp_status === crosswarp.warp_boy_status) {
                localWarpId = crosswarp.warp_boy_id;
            } else if (crosswarp.warp_status === crosswarp.warp_girl_status) {
                localWarpId = crosswarp.warp_girl_id;
            }
        }

        return localWarpId;
    }, [crosswarp]);

    // ---- If we are not admin and a garment has not the correct warp feedback priority ----
    const feedbackStatusNotAllow = useMemo(() => (
        !!(!isAdmin && crosswarp.crosswarp_feedback_priority && !['CRITICAL', 'IMPROVE'].includes(crosswarp.crosswarp_feedback_priority))
    ),
    [isAdmin, crosswarp]);

    // ---- Crosswarp is ready to be QC but needs the crosswarp creation ----
    const allowWithCrosswarpCreation = useMemo(() => (
        crosswarp.crosswarp_status === PREFILTERS.MISSINGOVERRIDE && !crosswarp.crosswarp_id
    ),
    [crosswarp]);

    const isCWDisabled = useMemo(() => crosswarp.warp_status !== PREFILTERS.READY, [crosswarp]);
    const cwHref = useMemo(() => {
        if (isCWDisabled) {
            return undefined;
        }

        return `/crosswarp/${warpId}?${getCleanSearch(search)}`;
    }, [isCWDisabled, crosswarp, search]);

    const isEditDisabled = useMemo(() => (!crosswarp.crosswarp_id && crosswarp.crosswarp_status !== PREFILTERS.MISSINGOVERRIDE)
    || !crosswarpWarpPermission
    || feedbackStatusNotAllow,
    [crosswarp, feedbackStatusNotAllow, crosswarpWarpPermission]);
    const editHref = useMemo(() => {
        if (isEditDisabled) {
            return undefined;
        }

        if (allowWithCrosswarpCreation) {
            return `/crosswarp_creation/redirect?${getCleanSearch(search)}&garmentId=${
                crosswarp.garment_id}&modelId=${crosswarp.model_id}&redirect=/warp/:crosswarpId/edit`;
        }

        if (crosswarp.crosswarp_id) {
            return `/warp/${crosswarp.crosswarp_id}/edit?${getCleanSearch(search)}`;
        }

        return `/garment/${crosswarp.garment_id}/warp?${getCleanSearch(search)}`;
    }, [isEditDisabled, crosswarp, allowWithCrosswarpCreation]);

    const isQCDisabled = useMemo(() => (!crosswarp.crosswarp_id && crosswarp.crosswarp_status !== PREFILTERS.MISSINGOVERRIDE)
        || !crosswarpWritePermission
        || feedbackStatusNotAllow,
    [crosswarp, feedbackStatusNotAllow, crosswarpWritePermission]);
    const qcHref = useMemo(() => {
        if (isQCDisabled) {
            return undefined;
        }

        if (allowWithCrosswarpCreation) {
            return `/crosswarp_creation/redirect?${getCleanSearch(search)}&garmentId=${
                crosswarp.garment_id}&modelId=${crosswarp.model_id}&redirect=/crosswarp/:crosswarpId/quality`;
        }

        return `/crosswarp/${crosswarp.crosswarp_id}/quality?${getCleanSearch(search)}`;
    }, [isQCDisabled, crosswarp, search, allowWithCrosswarpCreation]);

    useEffect(() => {
        function handleResize() {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setCardWidth(rect.width);
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [ref.current?.getBoundingClientRect()]);

    useEffect(() => {
        if (isReservedLocal || !isReserved) {
            return;
        }

        setIsReservedLocal(isReserved);
    }, [isReserved]);

    return (
        <VStack
            backgroundColor="gray.100"
            border="2px solid"
            borderColor={'transparent'}
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            ref={ref}
            w="100%"
        >
            <Box
                borderRadius="lg"
                h={0}
                overflow="hidden"
                paddingBottom={imageRatio}
                position="relative"
                w="100%"
            >
                <LazyLoad height="100%" offset={100} once overflow>
                    <ImageWithError
                        cursor="pointer"
                        fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg" /></Center>}
                        height="100%"
                        objectFit={'contain'}
                        // onClick={handleCheck}
                        position="absolute"
                        src={crosswarp.crosswarp_image_url ? resizeImage(crosswarp.crosswarp_image_url, { width: 800 }) : ''}
                        width="100%"
                    />
                </LazyLoad>
            </Box>
            <VStack justifyContent="space-between" spacing={2} w="100%">
                <VStack alignItems="flex-start" maxW="100%" minW={30}>
                    <Text
                        fontSize={fontSize}
                        noOfLines={[1, 2]}
                        textAlign='center'
                        {...TEXT_OVERFLOW_PROPS}
                    >
                        {crosswarp.garment_name}
                    </Text>
                    {
                        isReserved
                            ? <Text
                                fontSize={fontSize}
                                fontWeight="bold"
                                textAlign='center'
                                whiteSpace="nowrap"
                                {...TEXT_OVERFLOW_PROPS}
                                color="red"
                            >
                                {t('reserved', { ns: COMMON_LOCALES })}
                            </Text>
                            : <Text
                                fontSize={fontSize}
                                fontWeight="bold"
                                textAlign='center'
                                whiteSpace="nowrap"
                                {...TEXT_OVERFLOW_PROPS}
                            >
                                {crosswarp[statusKey || 'garment_status']}
                            </Text>
                    }

                </VStack>
                <HStack flex={1} justifyContent="space-evenly" minW={90} spacing={1} w="100%">
                    <Restricted to="crosswarp.internal">
                        <RoundButton
                            href={cwHref}
                            isDisabled={isCWDisabled}
                            onClick={() => navigate(`/crosswarp/${warpId}`)}
                            text="CW"
                            tooltipLabel={t('cw')}
                        />
                    </Restricted>
                    <Restricted to="crosswarp.warp">
                        <RoundButton
                            href={editHref}
                            isDisabled={isEditDisabled}
                            onClick={() => navigate(editHref)}
                            text="Edit"
                        />
                    </Restricted>
                    <Restricted to="crosswarp.write">
                        <RoundButton
                            href={qcHref}
                            isDisabled={isQCDisabled}
                            onClick={() => navigate(qcHref)}
                            text="QC"
                            tooltipLabel={t(crosswarp.crosswarp_status === PREFILTERS.MISSINGSIZING
                                || crosswarp.crosswarp_status === PREFILTERS.MISSINGALLOWED
                                ? `warning_${crosswarp.crosswarp_status.toLowerCase()}` : 'qc')}
                        />
                    </Restricted>
                </HStack>
            </VStack>
        </VStack>

    );
}
