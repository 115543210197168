import { Button, Text, Tooltip, VStack } from '@chakra-ui/react';
import React from 'react';

import preventCmdCtrlClick from '../../utils/click-helpers';

interface RoundButtonProps {
    onClick?(): void
    text?: string
    secondLine?: string
    href?: string
    tooltipLabel?: string
    isDisabled?: boolean
    isLoading?: boolean
}

export default function RoundButton(props: RoundButtonProps) {
    const { onClick, text, secondLine, href, tooltipLabel, isDisabled, isLoading } = props;

    return (
        <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement="top">
            <Button
                as="a"
                fontSize={11}
                href={href}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onClick={(e) => {
                    if (isDisabled || isLoading) {
                        return;
                    }

                    if (onClick) {
                        preventCmdCtrlClick(e, () => onClick());
                    }
                }}
                variant="round"
            >
                <VStack spacing={0}>
                    {text && <Text>{text}</Text>}
                    {secondLine && <Text>{secondLine}</Text>}
                </VStack>
            </Button>
        </Tooltip>
    );
}
