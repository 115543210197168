import { Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getIsCrosswarpView, setIsCrosswarpView } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Facets, FacetTerms, Prefilter } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_FEEDBACK_PRIORITY, CROSSWARP_LOCALES, CROSSWARP_STATUS_KEY, PREFILTERS } from '../../utils/constants';
import PreFilterButton from '../buttons/PreFilterButton';
import Restricted from '../permission/Restricted';
// import { useProductsContext } from '../products/ProductsContext';

const CrossWarpSubHeader = (props: {facets?: Facets, initFacets?: Facets}) => {
    // const ProductContext = useProductsContext();
    const { t } = useTranslation([COMMON_LOCALES, CROSSWARP_LOCALES]);
    const dispatch = useDispatch();

    const { facets, initFacets } = props;

    const isCrosswarpView = useAppSelector((state) => getIsCrosswarpView(state));

    const prefilters: Prefilter[] = [
        { label: t('prefilters.sizing', { ns: COMMON_LOCALES }), values: [PREFILTERS.MISSINGSIZING] },
        { label: t('prefilters.pending', { ns: COMMON_LOCALES }), values: [PREFILTERS.MISSINGALLOWED] },
        { label: t('prefilters.to_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOQUALITY, PREFILTERS.MISSINGOVERRIDE] },
        { label: t('prefilters.hd_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOHDQC] },
        {
            label: t('prefilters.has_feedback', { ns: COMMON_LOCALES }),
            subfilters: [
                {
                    filterKey: CROSSWARP_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_1', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P1, PREFILTERS.P2],
                },
                {
                    filterKey: CROSSWARP_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_7', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P7],
                },
                {
                    filterKey: CROSSWARP_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_8', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P8],
                },
                {
                    filterKey: CROSSWARP_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_9', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P9],
                },
            ],
            values: [PREFILTERS.HASFEEDBACK],
        },
    ];

    return (
        <HStack justifyContent="space-between" w="100%">
            <HStack>
                {
                    facets && prefilters.map((prefilter, index) => (
                        prefilter && (
                            <PreFilterButton
                                currentFacet={facets[prefilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms}
                                filterKey={prefilter.filterKey || CROSSWARP_STATUS_KEY}
                                initFacet={initFacets ? initFacets[prefilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms : undefined}
                                key={index}
                                label={prefilter.label}
                                overrideDisabled={prefilter.isDisabled || !(facets[prefilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms)}
                                values={prefilter.values}
                            >
                                {
                                    prefilter.subfilters && <HStack>
                                        {
                                            prefilter.subfilters.map((subfilter) => <PreFilterButton
                                                containerStyle={{ h: '34px' }}
                                                currentFacet={facets[subfilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms}
                                                filterKey={subfilter.filterKey || CROSSWARP_STATUS_KEY}
                                                initFacet={
                                                    initFacets
                                                        ? initFacets[subfilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms
                                                        : undefined
                                                }
                                                key={subfilter.label}
                                                label={subfilter.label}
                                                overrideDisabled={
                                                    subfilter.isDisabled || !(facets[subfilter.filterKey || CROSSWARP_STATUS_KEY] as FacetTerms)
                                                }
                                                values={subfilter.values}
                                            />)
                                        }
                                    </HStack>
                                }
                            </PreFilterButton>
                        )
                    ))
                }
            </HStack>
            <HStack spacing={4}>
                {/* <VStack alignItems={'flex-end'}>
                    <div>
                        {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                    </div>
                    {
                        ProductContext
            && <HStack spacing={8}>
                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                    {t('grid.select_all')}
                </Button>
                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                    {t('grid.reset')}
                </Button>
            </HStack>
                    }
                </VStack> */}
                <Restricted to="crosswarp.internal">
                    <Button
                        onClick={() => dispatch(setIsCrosswarpView(!isCrosswarpView))}
                        variant="outline"
                    >
                        {isCrosswarpView ? t('garment_view', { ns: CROSSWARP_LOCALES }) : t('crosswarp_view', { ns: CROSSWARP_LOCALES })}
                    </Button>
                </Restricted>
            </HStack>
        </HStack>

    );
};

export default CrossWarpSubHeader;
