import { Text, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePostCreateCrosswarpMutation } from '../../services/api/api-warp';
import { getClient } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import useCustomNavigate from '../../utils/custom-navigate-hook';

export default function CrosswarpCreation() {
    const [searchParams] = useSearchParams();
    const navigate = useCustomNavigate();

    const currentClient = useAppSelector((state) => getClient(state));

    const [postCreateCrosswarp] = usePostCreateCrosswarpMutation();

    // ---- Redirect to the correct page and clean the search params ----
    const handleRedirect = (redirect: string, crosswarpId: string) => {
        // ---- Check for match to inject new crosswarpId ----
        if (redirect.match(':crosswarpId')) {
            return navigate(
                redirect.replace(':crosswarpId', crosswarpId),
                { replace: true },
                { garmentId: null, modelId: null, redirect: null },
            );
        }

        // ---- Default redirect if no match ----
        return navigate('/crosswarp', { replace: true }, { garmentId: null, modelId: null, redirect: null });
    };

    useEffect(() => {
        // ---- Var init ----
        const garmentId = searchParams.get('garmentId');
        const modelId = searchParams.get('modelId');
        const redirect = searchParams.get('redirect');

        // ---- Needed var check ----
        if (!garmentId || !modelId || !currentClient || !redirect) {
            return undefined;
        }

        // ---- Creation API Call ----
        postCreateCrosswarp(
            { clientId: currentClient.id, garmentId, modelId },
        ).unwrap().then((resp) => {
            handleRedirect(redirect, resp.id);
        }).catch((e) => {
            console.error(e);
            navigate('/crosswarp', { replace: true }, { garmentId: null, modelId: null, redirect: null });
        });

        return undefined;
    }, [searchParams, currentClient]);

    return (
        <VStack alignItems="center" h="100%" justifyContent="center"><Text>Redirecting...</Text></VStack>
    );
}
